import React, {Fragment, Suspense} from 'react';
import PropTypes from 'prop-types';
import Component from './entity/Component';
import { getAnchorIdFromEntity } from './Anchor';
import Loader from './Loader';

class Block extends Component {

  renderBlockFromDocument(extraAttributes, onClickCallback, customEvent, index) {
    const document = this.getDocument();

    if (document && document.fields && document.fields.contentType) {
      let contentType = document.fields.contentType;
      let blockName = document.fields.name;
      let className = document.fields.classNames;

      let attributes = {
        name: blockName,
        document: document,
        className: className,
        onClickCallback,
        customEvent,
        key: blockName,
        index,
        ...extraAttributes
      };

      return this.renderBlock(contentType, attributes, null);

    } else {
      console.error('Unsupported block is currently assign to page:' + this.props.page.fields.name);
    }
  }

  /**
   * Render the modules assigned to the page.
   * @returns {*}
   */
  renderChildBlocks(extraAttributes, onClickCallback, customEvent, lazyloadIndexValue) {
    const doc = this.getDocument(lazyloadIndexValue);

    return ((doc.fields && doc.fields.blocks) ?
      <Fragment>
        {doc.fields.blocks.map((child, index) =>
            // Check if Lazy flag is available, else render the block as it is.
          (child?.fields?.hasOwnProperty('isLazy') && child.fields.isLazy) ?
            // Check if Lazy flag is false then render the first content, Else do lazy load for rest of the content
            <LazyLoad offset={100}>
              {(new Block(child).renderBlockFromDocument(extraAttributes, onClickCallback, customEvent, index))}
            </LazyLoad>
            : (new Block(child).renderBlockFromDocument(extraAttributes, onClickCallback, customEvent, index))
        )}
      </Fragment>
    :
      null
    );
  }

  getAnchorId() {
    return getAnchorIdFromEntity(this.getDocument());
  }

  getShortName() {
    let shortName = this.getFieldValue('shortName');

    if (shortName == null) {
      shortName = this.getFieldValue('anchorId');
    }

    if (shortName == null) {
      shortName = this.getFieldValue('name');
    }

    return shortName;
  }
  renderSpecifiedBlock(blockToRender){
    return ((blockToRender) ?
      <Fragment>
        <Suspense fallback={<Loader />}>
          {blockToRender.map((child) => (
              new Block(child).renderBlockFromDocument()
          ))}
        </Suspense>
      </Fragment>
    :
      null
    );  
  }
}

Block.propTypes = {
  page: PropTypes.object
}

export default Block
